@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: #ffa7829f;
}

details[open] > summary {
  font-weight: bolder;
  border-bottom: 1px solid black;
}

a.active {
  background: #ffa782;
  color: #000;
}

/* ck editor */
.ck.ck-editor {
  width: 100% !important;
  margin: 0;
  min-height: 400px;
}

.ck.ck-editor__editable {
  background: #fff;
  border: 1px solid hsl(0, 0%, 70%);
  width: 100%;
  min-height: 400px;
}
