#form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  cursor: pointer;
  border-radius: 5px;
}

#upload_heading {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}

#upload-icon {
  font-size: 50px;
}

#form p {
  margin-top: 15px;
  font-size: 16px;
}

.uploaded-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 15px 20px;
  border-radius: 5px;
}